import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  EmailField,
  Edit,
  Create,
  DateInput,
  SimpleForm,
  TextInput,
  DateField,
  SelectInput
} from "react-admin";

export const F191_studentList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField source="id" reference="person" label="Nome">
        <TextField source="first_name" />
      </ReferenceField>
      <ReferenceField source="id" reference="person" label="Sobrenome">
        <TextField source="last_name" />
      </ReferenceField>
      <TextField source="turma_quarta" />
      <TextField source="nivel_pos" />
      <TextField source="imagem_residencia" />
      <TextField source="instituicao_pos" />
      <TextField source="nivel_escolaridade" />
      <TextField source="instituicao_graduacao" />
      <TextField source="imagem_rg" />
      <TextField source="imagem_certidao" />
    </Datagrid>
  </List>
);

export const F191_studentEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceField source="id" reference="person" label="Nome">
        <TextField source="first_name" />
      </ReferenceField>
      <ReferenceField source="id" reference="person" label="Sobrenome">
        <TextField source="last_name" />
      </ReferenceField>
      <ReferenceField source="id" reference="person" label="Email">
        <TextField source="email" />
      </ReferenceField>
      <TextInput source="turma_quarta" />
      <TextInput source="nivel_escolaridade" />
      <TextInput source="instituicao_graduacao" />
      <TextInput source="nivel_pos" />
      <TextInput source="instituicao_pos" />
      <TextInput source="imagem_rg" />
      <TextInput source="imagem_certidao" />
      <TextInput source="imagem_residencia" />
    </SimpleForm>
  </Edit>
);

export const F191_studentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Email" source="id" reference="person">
        <SelectInput optionText="email" />
      </ReferenceInput>
      <TextInput source="turma_quarta" />
      <TextInput source="nivel_escolaridade" />
      <TextInput source="instituicao_graduacao" />
      <TextInput source="nivel_pos" />
      <TextInput source="instituicao_pos" />
      <TextInput source="imagem_rg" />
      <TextInput source="imagem_certidao" />
      <TextInput source="imagem_residencia" />
    </SimpleForm>
  </Create>
);
