import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Edit,
  DateInput,
  SimpleForm,
  TextInput,
  DateField,
  SelectInput,
  Create,
  Show,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab
} from "react-admin";

export const PersonList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="first_name" label="Nome" />
      <TextField source="last_name" label="Sobrenome" />
      <EmailField source="email" />
      <TextField source="cell_phone" label="celular" />
      <DateField
        source="dob"
        label="Data Nasc"
        locales="pt-BR"
        options={{ timeZone: "UTC" }}
      />
      <TextField source="gender" label="Gênero" />
      <TextField source="occupation" label="Ocupação" />
      <TextField source="city" label="Cidade" />
      <TextField source="state" label="UF" />
      <TextField source="cpf" label="CPF" />
      <TextField source="rg" label="RG" />
    </Datagrid>
  </List>
);

export const PersonEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="básico">
        <TextInput source="first_name" label="Nome" />
        <TextInput source="last_name" label="Sobrenome" />
        <TextInput source="email" />
        <TextInput source="cell_phone" label="celular" />
        <DateInput source="dob" label="Data Nasc" />
        <SelectInput
          source="gender"
          label="Gênero"
          choices={[
            { id: "M", name: "Masculino" },
            { id: "F", name: "Feminino" },
            { id: "O", name: "Outro" }
          ]}
        />
        <TextInput source="occupation" label="Ocupação" />
        <TextInput source="cpf" label="CPF" />
        <TextInput source="rg" label="RG" />
        <TextInput source="profile_pic" />
      </FormTab>
      <FormTab label="endereço">
        <TextInput source="address1" label="Endereço" />
        <TextInput source="address2" label="Complemento" />
        <TextInput source="city" label="Cidade" />
        <TextInput source="state" label="UF" />
        <TextInput source="postal_code" label="CEP" />
        <TextInput source="country" label="País" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const PersonCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="básico">
        <TextInput source="first_name" label="Nome" />
        <TextInput source="last_name" label="Sobrenome" />
        <TextInput source="email" />
        <TextInput source="cell_phone" label="celular" />
        <DateInput source="dob" label="Data Nasc" />
        <SelectInput
          source="gender"
          label="Gênero"
          choices={[
            { id: "M", name: "Masculino" },
            { id: "F", name: "Feminino" },
            { id: "O", name: "Outro" }
          ]}
        />
        <TextInput source="occupation" label="Ocupação" />
        <TextInput source="cpf" label="CPF" />
        <TextInput source="rg" label="RG" />
        <TextInput source="profile_pic" />
      </FormTab>
      <FormTab label="endereço">
        <TextInput source="address1" label="Endereço" />
        <TextInput source="address2" label="Complemento" />
        <TextInput source="city" label="Cidade" />
        <TextInput source="state" label="UF" />
        <TextInput source="postal_code" label="CEP" />
        <TextInput source="country" label="País" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export const PersonShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="básico">
        <TextField source="first_name" label="Nome" />
        <TextField source="last_name" label="Sobrenome" />
        <TextField source="email" />
        <TextField source="cell_phone" label="celular" />
        <DateField source="dob" label="Data Nasc" />
        <TextField source="gender" label="Gênero" />
        <TextField source="occupation" label="Ocupação" />
        <TextField source="cpf" label="CPF" />
        <TextField source="rg" label="RG" />
        <TextField source="profile_pic" />
      </Tab>
      <Tab label="endereço">
        <TextField source="address1" label="Endereço" />
        <TextField source="address2" label="Complemento" />
        <TextField source="city" label="Cidade" />
        <TextField source="state" label="UF" />
        <TextField source="country" label="País" />
        <TextField source="postal_code" label="CEP" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
