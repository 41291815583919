import React from "react";
import { Admin, Resource, ListGuesser, EditGuesser } from "react-admin";
import UserIcon from "@material-ui/icons/Group";
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import hasuraDataProvider from "ra-data-hasura";
import { PersonList, PersonEdit, PersonCreate, PersonShow } from "./persons";
import {
  F191_studentList,
  F191_studentEdit,
  F191_studentCreate
} from "./f191-students";

const headers = { "content-type": "application/json" };

const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={hasuraDataProvider(
      "https://relinkdb2.herokuapp.com",
      headers
    )}
  >
    <Resource
      name="person"
      list={PersonList}
      edit={PersonEdit}
      create={PersonCreate}
      show={PersonShow}
      options={{ label: "Pessoas" }}
    />
    <Resource
      name="f191_student"
      list={F191_studentList}
      edit={F191_studentEdit}
      create={F191_studentCreate}
      options={{ label: "Documentos" }}
    />
  </Admin>
);

export default App;
