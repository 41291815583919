import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

export default () => (
  <Card>
    <CardHeader title="Bem-vindo(a) ao Relink Admin!" />
    <CardContent>Versão 0.01 alpha</CardContent>
  </Card>
);
